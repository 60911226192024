$body-bg: #000000;
$second-bg: #085915;
$main-color: #fcdb04;
$main-color-hover: #fcdb04;
$txt-color: #000;
$txt-second-color: #757474;
$white: #fff;

$font-main: "Gilroy-Bold";
$font-second: "Roboto";

$bg-item-1 : #ffe100;
$bg-item-2 : rgb(34,193,195);
$bg-item-3 : #064c2f;

$header-height: 170px;
$header-shrink-height: 90px;

$mobile-width: 767px;
$tablet-width: 1024px;
$desktop-width: 1200px;
$desktop-1350: 1350px;
$desktop-1500: 1500px;
$desktop-1900 : 1900px;


$box-shadow: 0 10px 20px rgb(0 0 0 / 5%);
$bg-overlay: rgba(0,0,0,0.5);