/**
  * Name: EverMon | NFT Marketplace ReactJS Template
  * Version: 1.0.1
  * Author: Themesflat
  * Author URI: http://www.themesflat.com
*/

@use './scss/' as *;
@use './components/layouts/' as *;

@font-face {
  font-family:"Gilroy-Bold";
  src: url("assets/font/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
  font-family:"Roboto";
  src: url("assets/font/Roboto-Regular.ttf") format("truetype");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  color: $white;
  padding-top: $header-height;
  background-image: url('../src/assets/images/background/bg-element.jpg');

  &.home-1 {
      background-image: url('../src/assets/images/background/bg-element.jpg');
  }
  &.home-2 {
      background-image: url('../src/assets/images/background/bg-element-2.jpg');
  }
}

.row>* {
  padding-left: 15px;
  padding-right: 15px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: 'Gilroy-Bold';
  font-weight: bold;
  line-height: 1.2;
}

h2,
.h2 {
  font-size: 60px;
}
h3,
.h3 {
  font-size: 48px;
}
h5,
.h5 {
  font-size: 24px;
}
h6,
.h6 {
  font-size: 21px;
}

.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
  line-height: 1.7;
}
.fs-21 {
  font-size: 21px;
}
.fs-24 {
  font-size: 24px;
}
.fs-30 {
  font-size: 30px;
}
.fs-36 {
  font-size: 36px;
}
.fs-42 {
  font-size: 42px;
}
.mr-0 {
  margin-right: 0 !important;
}
.pt-128 {
  padding-top: 128px;
}
.mt--16 {
  margin-top: -16px;
}
.mt--23 {
  margin-top: -23px;
}
.mt-19 {
  margin-top: 19px;
}
.mt-27 {
  margin-top: 27px;
}
.mt-28 {
  margin-top: 28px;
}
.mt-29 {
  margin-top: 29px;
}
.mt-37 {
  margin-top: 37px;
}
.mt-42 {
  margin-top: 42px;
}
.mt-48 {
  margin-top: 48px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-51 {
  margin-top: 51px;
}
.mt-52 {
  margin-top: 52px;
}
.mt-53 {
  margin-top: 53px;
}
.mt-61 {
  margin-top: 61px;
}
.mt-104 {
  margin-top: 104px;
}
.mt-107 {
  margin-top: 107px;
}
.mt-150 {
  margin-top: 150px;
}
.mt-120 {
  margin-top: 120px;
}
.mt-200 {
  margin-top: 200px;
}
.mt-223 {
  margin-top: 223px;
}
.mt-300 {
  margin-top: 300px;
}
.mt-500 {
  margin-top: 500px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-6 {
  margin-bottom: 6px;
}
.mb-7 {
  margin-bottom: 7px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-13 {
  margin-bottom: 13px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-26 {
  margin-bottom: 26px;
}
.mb-28 {
  margin-bottom: 28px;
}
.mb-29 {
  margin-bottom: 29px;
}
.mb-31 {
  margin-bottom: 31px;
}
.mb-33 {
  margin-bottom: 33px;
}
.mb-37 {
  margin-bottom: 37px;
}
.mb-41 {
  margin-bottom: 41px;
}
.mb-43 {
  margin-bottom: 43px;
}
.mb-46 {
  margin-bottom: 46px;
}
.mb-53 {
  margin-bottom: 53px;
}
.mb-58 {
  margin-bottom: 58px;
}
.mr-28 {
  margin-right: 28px;
}
.pt-12 {
  padding-top: 12px;
}
.pt-24 {
  padding-top: 24px;
}
.pd-0 {
  @include tablet {
      padding: 0;
  }
}
.pb-team {
  padding: 0 150px;
  margin-top: 38px;

  @include tablet {
      padding: 0;
      margin-top: 0;
  }
}

.line-h17 {
  line-height: 1.7;
}
.container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

ul, li {
  list-style-type: none;
  margin-bottom: 0;
}

body a {
  text-decoration: none;
  color: unset;
  transition: all .3s;
  cursor: pointer;
  font-family: $font-main;
  display: inline-block;
  color: $white;
  text-decoration: none;

  &:hover {
      color: $main-color;
      transition: all .3s;
  }
}

.center {
  text-align: center;
}

.color-main {
  color: $main-color;
}
.color2 {
  color: #b9b9b9 !important;
}

.border-l {
  border-left-width: 1px !important;
}
.border-r {
  border-right-width: 1px !important;
}
.border-t {
  border-top-width: 1px !important;
}
.border-b {
  border-bottom-width: 1px !important;
}

.reverse {
  @include tablet {
      flex-direction: column-reverse;
  }
}

.d-m-none {

  @include tablet {
      display: none;
  }
  
}

.divider {
  background: rgba(255, 255, 255,0.302);
  height: 1px;
  width: 100%;
  margin: 10px 0;
}